import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/articles-layout.js";
import { Link } from "gatsby";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Setting up Windows Terminal`}</h1>
    <p>{`I've wrote a quick post about `}<Link to="/digital-garden/moving-to-windows" mdxType="Link">{`Moving to Windows from a Mac`}</Link>{`. On that post, I've mentioned how I've been using the `}<a parentName="p" {...{
        "href": ""
      }}>{`Windows Terminal`}</a>{` as my main terminal.`}</p>
    <p>{`The Windows Terminal is great, but has a few problems:`}</p>
    <ul>
      <li parentName="ul">{`Pretty simple theme (a grey text on a black background)`}</li>
      <li parentName="ul">{`It's not using my favourite shell `}<a parentName="li" {...{
          "href": "https://linuxhandbook.com/install-zsh/"
        }}>{`zsh shell`}</a></li>
      <li parentName="ul">{`No icons or interactive profiles`}</li>
    </ul>
    <p>{`The terminal itself it's pretty good and allows you to have different tabs on it. It also comes with integrated support for the Windows Subsystem for Linux, Windows Powershell, Azure cloud shell and the Command Prompt.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Windows Terminal is a modern terminal application for users of command-line tools and shells like Command Prompt, PowerShell, and Windows Subsystem for Linux (WSL). Its main features include multiple tabs, panes, Unicode and UTF-8 character support, a GPU accelerated text rendering engine, and the ability to create your own themes and customize text, colours, backgrounds, and shortcut key bindings.`}</p>
    </blockquote>
    <p>{`You can read more about the features of the Windows Terminal on the `}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/windows/terminal/"
      }}>{`official documentation website`}</a>{`.`}</p>
    <p>{`You can also download the Windows Terminal from the `}<a parentName="p" {...{
        "href": "https://www.microsoft.com/en-gb/p/windows-terminal/9n0dx20hk701?activetab=pivot:overviewtab"
      }}>{`Microsoft Store`}</a>{`.`}</p>
    <h2>{`Setting up WSL as the default profile`}</h2>
    <p>{`When you run the Windows Terminal, you can see that the Terminal opens the `}<em parentName="p">{`Windows PowerShell`}</em>{` by default. Since I am going to be using WSL all the time, I want this to be the default.`}</p>
    <p>{`We can change the default profile by opening the terminal menu (press the down arrow) and select settings.`}</p>
    <ul>
      <li parentName="ul">{`Open settings from the terminal menu`}</li>
      <li parentName="ul">{`Scroll down to find the `}<inlineCode parentName="li">{`guid`}</inlineCode>{` for the WSL`}</li>
      <li parentName="ul">{`Copy that `}<inlineCode parentName="li">{`guid`}</inlineCode>{` and scroll back up`}</li>
      <li parentName="ul">{`Paste the WSL `}<inlineCode parentName="li">{`guid`}</inlineCode>{` inside the `}<inlineCode parentName="li">{`defaultProfile`}</inlineCode>{` setting`}</li>
    </ul>
    <p>{`Now every time we run the terminal, the default profile will be the WSL one.`}</p>
    <h2>{`Install and setup zsh and Oh-My-Zsh`}</h2>
    <p>{`The next thing we need to do is installing the `}<inlineCode parentName="p">{`zsh`}</inlineCode>{` shell in our WSL. Open the Windows Terminal (if you haven't it open already) and run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`sudo apt update && sudo apt install zsh -y
`}</code></pre>
    <p>{`This command will install the zsh shell, then we can install `}<a parentName="p" {...{
        "href": "https://ohmyz.sh/"
      }}>{`oh-my-zsh`}</a>{` which is zsh on steroids. It comes with more functions, helpers, plugins, themes and much more.`}</p>
    <p>{`We can install oh-my-zsh by running the command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`sh -c "$(curl -fsSL https://raw.github.com/ohmyzsh/ohmyzsh/master/tools/install.sh)"
`}</code></pre>
    <p>{`Now that we have the zsh shell and oh-my-zsh installed, we can go to the next step. Customizing your terminal to look beautiful!`}</p>
    <h2>{`Pimp my terminal - Themes, Icons, Profiles`}</h2>
    <p>{`Remember that I said earlier in this article, that the Windows Terminal was pretty simple? Well, it's now time to pimp our terminal and change how it looks! We will do a few things:`}</p>
    <ul>
      <li parentName="ul">{`Install Powerlevel10k`}</li>
      <li parentName="ul">{`Install a font that contains icons`}</li>
      <li parentName="ul">{`Adding the font to Windows Terminal by using Nerd Fonts`}</li>
      <li parentName="ul">{`Choosing a decent theme for the terminal`}</li>
      <li parentName="ul">{`Changing the theme for the terminal`}</li>
    </ul>
    <h3>{`Installing Powerlevel10k`}</h3>
    <p>{`If you are unfamiliar with Powerlevel10k, the quick way to explain it to you is saying that it's a theme for zsh. It allows you to customize your terminal prompt from a set of default styles.`}</p>
    <p>{`You can `}<a parentName="p" {...{
        "href": "https://github.com/romkatv/powerlevel10k"
      }}>{`read more about Powerlevel10K`}</a>{` on the official GitHub repo of the project.`}</p>
    <p>{`To install Powerlevel10K, you can run this command or `}<a parentName="p" {...{
        "href": "https://github.com/romkatv/powerlevel10k#get-started"
      }}>{`read the getting started documentation`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`git clone --depth=1 https://github.com/romkatv/powerlevel10k.git \${ZSH_CUSTOM:-~/.oh-my-zsh/custom}/themes/powerlevel10k
`}</code></pre>
    <p>{`Then you will have to edit the `}<inlineCode parentName="p">{`~/.zshrc`}</inlineCode>{` file to activate the powerlevel10k theme. Look for the line that contains:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`ZSH_THEME="robbyrussel"
`}</code></pre>
    <p>{`Replace it with`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`ZSH_THEME="powerlevel10k/powerlevel10k"
`}</code></pre>
    <h3>{`Installing a font pack that contains icons`}</h3>
    <p>{`We need to get a font that contains text and icons on the same pack. `}<a parentName="p" {...{
        "href": "https://www.nerdfonts.com/"
      }}>{`Nerd Fonts`}</a>{` has a lot of different font styles, all supporting the icons that we need.`}</p>
    <p>{`The one that I've been using all the time is the `}<strong parentName="p">{`DroidSansMono Nerd Font`}</strong>{` that you can find on the `}<a parentName="p" {...{
        "href": "https://www.nerdfonts.com/font-downloads"
      }}>{`font downloads page`}</a>{`. You can also preview some of these fonts on `}<a parentName="p" {...{
        "href": "https://www.programmingfonts.org/"
      }}>{`Programming Fonts`}</a>{`.`}</p>
    <p>{`Chose a font that you like, download it and install the font.`}</p>
    <p>{`Now you need to open your Windows Terminal settings and scroll down to the WSL settings and add the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`"fontFace": "DroidSansMono NF"
`}</code></pre>
    <p><em parentName="p">{`Note: You need to use the same font name as the one shown when you open the `}<inlineCode parentName="em">{`.otf`}</inlineCode>{` file.`}</em></p>
    <p>{`So your WSL settings will look like this.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "guid": "{07b52e3e-de2c-5db4-bd2d-ba144ed6c273}",
    "hidden": false,
    "name": "Ubuntu-20.04",
    "source": "Windows.Terminal.Wsl",
    "fontFace": "DroidSansMono NF"
}
`}</code></pre>
    <p><em parentName="p">{`Note: your guid will have a unique number, don't copy this section of the code.`}</em></p>
    <h3>{`Activating Powerlevel10K with the new font`}</h3>
    <p>{`After updating your `}<inlineCode parentName="p">{`~/zshrc`}</inlineCode>{` file together with using a Nerd Font on the terminal, you should restart your terminal. By restarting the terminal, Powerlevel10K should say that it will try to configure itself, but if that doesn't happen, you can start the configure prompt by running the command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`p10k configure
`}</code></pre>
    <p>{`This will go through a series of questions and styles that you can choose. Select the options to get the style and combination that looks best for your needs.`}</p>
    <h3>{`Updating the Windows Terminal colour scheme`}</h3>
    <p>{`Finally, we want to update the colour scheme of the terminal to look better than just the grey text on a black background.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://windowsterminalthemes.dev/"
        }}>{`Windows Terminal Themes`}</a></li>
    </ul>
    <p>{`I recommend this site because it allows you to see in real-time the different colour schemes and you can then click the `}<strong parentName="p">{`Get Theme`}</strong>{` button to get the JSON for that colour scheme.`}</p>
    <p>{`After you've picked a colour scheme that you like, open the Windows Terminal settings again and locate the line that says `}<inlineCode parentName="p">{`schemes: []`}</inlineCode>{`. In this line, you can paste the colour scheme that you picked.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`    "schemes": [
        {
            "name": "Tinacious Design (Dark)",
            "black": "#1d1d26",
            "red": "#ff3399",
            "green": "#00d364",
            "yellow": "#ffcc66",
            "blue": "#00cbff",
            "purple": "#cc66ff",
            "cyan": "#00ceca",
            "white": "#cbcbf0",
            "brightBlack": "#636667",
            "brightRed": "#ff2f92",
            "brightGreen": "#00d364",
            "brightYellow": "#ffd479",
            "brightBlue": "#00cbff",
            "brightPurple": "#d783ff",
            "brightCyan": "#00d5d4",
            "brightWhite": "#d5d6f3",
            "background": "#1d1d26",
            "foreground": "#cbcbf0"
        }
    ],
`}</code></pre>
    <p>{`In my case, I've picked `}<strong parentName="p">{`Tinacious Design`}</strong>{` because that's the theme that I am currently using in VS Code.`}</p>
    <p>{`To activate the theme, we have to also tell Windows Terminal which profile should use this theme. Going back to the WSL configuration, we can add the following option:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`"colorScheme": "Tinacious Design (Dark)" 
`}</code></pre>
    <p>{`So our WSL configuration will look similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "guid": "{07b52e3e-de2c-5db4-bd2d-ba144ed6c273}",
    "hidden": false,
    "name": "Ubuntu-20.04",
    "source": "Windows.Terminal.Wsl",
    "fontFace": "DroidSansMono NF",
    "colorScheme": "Tinacious Design (Dark)"
}
`}</code></pre>
    <p>{`By saving the file, we can see that our terminal already changed colours and it's now using the colour scheme that we want.`}</p>
    <h2>{`Set your WSL as default when opening a new terminal`}</h2>
    <p>{`You might notice that after opening the windows terminal back and forth, that it defaults to the Powershell. `}</p>
    <p>{`If you are like me and are just using the WSL for all your dev needs, you probably want to set that shell as your default one.`}</p>
    <p>{`Let's open the terminal settings again and scroll down to your WSL settings.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "guid": "{07b52e3e-de2c-5db4-bd2d-ba144ed6c273}",
    "hidden": false,
    "name": "Ubuntu-20.04",
    "source": "Windows.Terminal.Wsl",
    "fontFace": "DroidSansMono NF",
    "colorScheme": "Tinacious Design (Dark)"
}
`}</code></pre>
    <p>{`Copy your `}<inlineCode parentName="p">{`guid`}</inlineCode>{` and then scroll back up and look for the `}<inlineCode parentName="p">{`"defaultProfile"`}</inlineCode>{` setting. We can then replace that with the WSL `}<inlineCode parentName="p">{`guid`}</inlineCode>{`, in my case I have to change this setting like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "defaultProfile": "{07b52e3e-de2c-5db4-bd2d-ba144ed6c273}"
}
`}</code></pre>
    <p>{`Save the file, restart your terminal and you will see that it will open your WSL shell instead of the Windows Powershell.`}</p>
    <h2>{`Setting a default starting directory`}</h2>
    <p>{`The last thing that is worth mentioning on this article, is that, by default, when opening the terminal it starts the shell on `}<inlineCode parentName="p">{`mnt/c/Users/<your username>`}</inlineCode>{`. `}</p>
    <p>{`If you are happy to have this as your default starting directory, then you are all set. If you would rather change that starting location to the home folder instead, you can open the terminal settings and scroll down to your WSL settings.`}</p>
    <p>{`We can have a look at the `}<em parentName="p">{`starting directory`}</em>{` setting on the `}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/windows/terminal/customize-settings/profile-settings#starting-directory"
      }}>{`Windows Terminal documentation`}</a>{`, we have the `}<inlineCode parentName="p">{` startingDirectory`}</inlineCode>{` property that allows us to set the directory that the shell starts in when it's loaded.`}</p>
    <p>{`Let's update the WSL setting with this `}<inlineCode parentName="p">{`startingDirectory`}</inlineCode>{` property.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "guid": "{07b52e3e-de2c-5db4-bd2d-ba144ed6c273}",
    "hidden": false,
    "name": "Ubuntu-20.04",
    "source": "Windows.Terminal.Wsl",
    "fontFace": "DroidSansMono NF",
    "colorScheme": "Tinacious Design (Dark)",
    "startingDirectory": "\\\\\\\\wsl$\\\\<distro name>\\\\home\\\\<username>"
}
`}</code></pre>
    <p><em parentName="p">{`Note: Don't copy this as you will have to change the `}<inlineCode parentName="em">{`<distro name`}</inlineCode>{` and `}<inlineCode parentName="em">{`<username`}</inlineCode>{` for your distro of choice and username.`}</em></p>
    <p>{`So in my case, I am using `}<strong parentName="p">{`Ubuntu 20.04`}</strong>{` as my distro and `}<strong parentName="p">{`FabioRosado`}</strong>{` as my username so this setting will become:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`"startingDirectory": "\\\\\\\\wsl$\\\\Ubuntu-20.04\\\\home\\\\FabioRosado"
`}</code></pre>
    <p>{`An important thing to mention here is that, if you mess up your path somehow it will just put the shell back on `}<inlineCode parentName="p">{`mnt/c/Users/<your username>`}</inlineCode>{` so there are no issues if you make a mistake.`}</p>
    <p>{`I hope this helps you, if it does or if you have any suggestions feel free to tweet `}<a parentName="p" {...{
        "href": "https://twitter.com/FabioRosado_"
      }}>{`@FabioRosado_`}</a></p>
    <hr></hr>
    <p><strong parentName="p">{`References`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nickymeuleman.netlify.app/blog/windows-terminal"
        }}>{`The Windows Terminal by Nicky Meuleman`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://blog.nillsf.com/index.php/2020/02/17/setting-up-wsl2-windows-terminal-and-oh-my-zsh/"
        }}>{`Setting up WSL2, Windows Terminal and oh-my-zsh by Nills`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.ivaylopavlov.com/setting-up-windows-terminal-wsl-and-oh-my-zsh/"
        }}>{`Setting up Windows Terminal, WSL and oh-my-zsh by Ivaylo Pavlov`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.microsoft.com/en-us/windows/terminal/customize-settings/profile-settings#starting-directory"
        }}>{`Profile settings in Windows Terminal`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      